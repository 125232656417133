<template>
  <div class="order-info">
    <div class="pay-status">
      <div class="un-pay">
        <img :src="timeLock"/>
        <template v-if="orderInfo.status == 0">
          您还没有支付
        </template>
        <template v-if="orderInfo.status == 1">
          订单已支付
        </template>
      </div>
    </div>


    <div class="order-info-container">
      <div class="order-info-line">
        <div class="order-info-line-left">订单编号</div>
        <div class="order-info-line-right">
          {{ orderInfo.order_sn }}
        </div>
      </div>
      <div class="order-info-line">
        <div class="order-info-line-left">下单时间</div>
        <div class="order-info-line-right">
          {{ orderInfo.create_time }}
        </div>
      </div>
      <div class="order-info-line no-border">
        <div class="order-info-line-left">核销截止</div>
        <div class="order-info-line-right">
          {{ orderInfo.expire_verify_time }}
        </div>
      </div>
    </div>


    <div class="order-goods">
      <div class="cover">
        <img :src="orderInfo.goods_cover"/>
      </div>
      <div class="order-goods-info">
        <div class="goods-title">
          {{ orderInfo.goods_title }}
        </div>
        <div class="goods-price">
          ¥ {{ orderInfo.order_amount }}
        </div>
        <div class="warning">

        </div>
      </div>
    </div>


    <div class="stores-goods-info">
      <div :class="[isHide ? 'goods-list-limit' : '']" class="goods-list-container">
        <div class="goods-list" v-for="(item,index) in orderInfo.details" :key="index">
          <div class="store-info">
            <div class="store-logo">
              <img :src="require('@STATIC/image/icons/shop.png')"/>
            </div>
            <div class="store-name">{{ item.store_name }}</div>
          </div>
          <div class="goods" v-for="(goods,goodsIndex) in item.goods" :key="goodsIndex">
            <div class="goods-cover">
              <img :src="goods.cover">
            </div>
            <div class="goods-info">
              <div class="goods-info-title">{{ goods.title }}</div>
              <div class="goods-d-market-price">还可核销 {{ goods.left_verify_times }} 次</div>
              <div class="goods-d-market-price">已核销 {{ goods.verify_times }} 次</div>
              <div class="use-btn" v-if="orderInfo.status == 1 && goods.left_verify_times > 0">
                <div class="button" @click="showUseCode(item.store_id,item.order_id,goods.goods_id,goods.title)">使用
                </div>
              </div>
              <div class="done-info" v-if="goods.is_done">
                已完成
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="view-more" @click="isHide = !isHide">
        <img v-if="!isHide" :src="require('@STATIC/image/icons/arrowUp.png')"/>
        <img v-if="isHide" :src="require('@STATIC/image/icons/arrowDown.png')"/>
      </div>
    </div>

    <nut-popup :style="{ padding: '10px 20px' }" :closeable="true" v-model="showCode" @close="getOrderInfo">
      <div class="verify-code">
        <p class="verify-title">{{ verifyGoods }}</p>
        <div class="code-image">
          <img :src="codePath">
        </div>
      </div>


    </nut-popup>

  </div>
</template>

<script>
import timeLock from "@STATIC/image/icons/timeLock.png";

export default {
  name: "join-order-info",
  data() {
    return {
      orderId: 0,
      timeLock,
      orderInfo: {},
      isHide: false,
      showCode: false,
      codePath: '',
      verifyGoods: ''
    }
  },
  mounted() {
    let route = this.$route
    this.orderId = route.query.order_id
    this.getOrderInfo()
    document.title = '联盟商户大礼包'
  },
  methods: {
    getOrderInfo() {
      let param = {
        order_id: this.orderId
      }
      this.$api.community.storeJoins.getStoreJoinOrderInfo(param).then(response => {
        // console.log('response', response)
        this.orderInfo = response.data
      })
    },
    showUseCode(store_id, order_id, goods_id, goods_title) {
      let params = {
        store_id: store_id,
        order_id: order_id,
        goods_id: goods_id
      }
      this.$api.community.storeJoins.getJoinGoodsUseCode(params).then(response => {
        // console.log('response', response)
        this.verifyGoods = goods_title
        this.codePath = response.data
        this.showCode = true
      })
    }
  }
};
</script>

<style scoped lang="scss">
.order-info {
  width: 100vw;
  min-height: 100vh;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;

  .pay-status {
    background: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px 15px;

    .un-pay {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      font-weight: 400;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }

  .order-info-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 10px 15px;
    margin-top: 10px;

    .order-info-line {
      border-bottom: #ebebeb solid 1px;
      display: flex;
      flex-direction: row;
      padding: 10px 0;

      .order-info-line-left {
        width: 100px;
        font-size: 14px;
      }

      .order-info-line-right {
        flex: 1;
        font-size: 12px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .no-border {
      border-bottom: none !important;
    }
  }

  .order-goods {
    background: #fff;
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
    margin-top: 10px;

    .cover {
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 10px;
      margin-right: 10px;

      img {
        width: 80px;
        height: 80px;
      }
    }

    .order-goods-info {
      flex: 1;
      display: flex;
      flex-direction: column;

      .goods-title, .goods-price {
        font-size: 16px;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      }

    }
  }

  .stores-goods-info {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    background: #fff;
    border-radius: 10px;

    .goods-list-container {
      .goods-list {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 10px 15px;

        .store-info {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 5px;

          .store-logo {
            width: 19px;
            height: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;

            img {
              width: 19px;
              height: 19px;
            }
          }

          .store-name {
            font-size: 14px;
          }
        }

        .goods {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;

          .goods-cover {
            width: 100px;
            height: 100px;
            border-radius: 10px;
            overflow: hidden;
            display: flex;


            img {
              width: 100px;
              height: 100px;
            }
          }

          .goods-info {
            flex: 1;
            flex-direction: column;

            .goods-info-title {
              font-size: 14px;
              display: flex;
              justify-content: flex-end;
              margin-bottom: 10px;
            }

            .goods-market-price, .goods-d-market-price {
              display: flex;
              justify-content: flex-end;
              font-size: 12px;
              margin-bottom: 5px;
            }

            .use-btn {
              display: flex;
              justify-content: flex-end;
              margin-top: 15px;

              .button {
                height: 20px;
                width: 40px;
                background: linear-gradient(
                        125deg,
                        #fab476 0%,
                        #eb6100 100%,
                        #eb6100 100%
                );
                border-radius: 4px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .done-info {
              display: flex;
              justify-content: flex-end;
              font-size: 12px;
              margin-top: 20px;
              color: #2f9e44;
            }
          }
        }
      }
    }

    .goods-list-limit {
      height: 140px;
      overflow: hidden;
    }

    .view-more {
      width: 20%;
      margin-left: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.1);
      margin-bottom: 5px;
      border-radius: 5px;
      padding: 3px 0;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.verify-code {
  .verify-title {
    font-size: 14px;
  }

  .code-image {
    width: 200px;
    height: 200px;

    img {
      width: 200px;
      height: 200px;
    }
  }
}
</style>
